// ** Initial State
const initialState = {
  nextAgent: null
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NEXT_AGENT':
      return { ...state, nextAgent: action.value }
    default:
      return state
  }
}

export default chatReducer
